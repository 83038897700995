@import url('https://fonts.googleapis.com/css2?family=Open Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.font-nunito {
    font-family: 'Nunito Sans', sans-serif;
}
.font-poppins {
    font-family: 'Open Sans', sans-serif;
}

.grey {
    color: #4f4f4f;
}
.modalFormItemsForm input,
textarea {
    background-color: #f8faff;
    font-size: 16px;
    border-color: #ebf0fa;
}
.modalFormItemsForm input::placeholder,
textarea::placeholder {
    color: #8ea3c3;
    letter-spacing: 0.5px;
}
.react-responsive-modal-modal,
.custom-modals {
    max-width: 900px !important;
    width: 100%;
    margin: 0 auto;
    border-radius: 16px;
    background: #f3f5f9 !important;
}
.text-wapas-blue {
    color: #1c4b82;
}
.react-tel-input .form-control {
    border: inherit;
    border-radius: 6px;
    height: auto;
    width: 100%;
}
.react-tel-input .flag-dropdown {
    background-color: #fff;
    border: none;
    border-radius: 6px 0 0 6px;
}
